<template>
  <div class="main-page">
    <van-loading v-if="loading" />
    <div class="clazz-container">

      <van-tree-select :items="dataList" :active-id.sync="activeId" :main-active-index.sync="activeIndex" @click-nav="changeCurNode">
        <template #content>
          <van-cell v-for="(clazz,idx) in curNode.children" :key="idx" :title="clazz.cnName" is-link center @click="toStudentList(clazz)">
            <template #label>
              <div> 学生人数：{{ clazz.personCnt }} 人</div>

              <div v-if="clazz.scheduleFlag">
                <span v-for="(schedule, iii) in clazz.clazzScheduleList" :key="iii">
                  <van-tag type="primary"> {{ schedule.weekday | formatWeekday }}</van-tag> {{ schedule.startTime }} ~{{ schedule.endTime }}
                </span>
              </div>
            </template>
          </van-cell>

        </template>
      </van-tree-select>
    </div>
  </div>
</template>
<script>
import eduApi from '@/api/edu'
export default {
  data() {
    return {
      appId: -2,
      dataList: [],
      curNode: {
        children: []
      },
      activeId: 1,
      activeIndex: 0,
      loading: false
    }
  },
  created() {
    this.appId = this.$route.params.appId
    this.loadClazzList()
  },
  mounted() {},
  methods: {
    loadClazzList() {
      this.loading = true
      eduApi.clazzList().then(res => {
        if (res.succ) {
          this.loading = false
          this.dataList = res.data.items
          // 默认显示第一个
          this.changeCurNode(this.activeIndex)
        }
      })
    },
    changeCurNode(idx) {
      this.curNode = this.dataList[idx]
      for (let i = 0; i < this.curNode.children.length; i++) {
        let clazzNode = this.curNode.children[i]
        if (clazzNode.clazzScheduleList && clazzNode.clazzScheduleList.length > 0) {
          clazzNode.scheduleFlag = true
        } else {
          clazzNode.scheduleFlag = false
        }
      }
    },
    toStudentList(item) {
      this.$router.push(`/${this.appId}/edu/clazz/${item.id}?cnName=` + encodeURIComponent(item.cnName))
    },
    toStudentList4LessonLog() {
      this.$router.push(`/${this.appId}/edu/lessonLog/studentList`)
    },
    toLessonLogList() {
      this.$router.push(`/${this.appId}/student/lessonLog/list`)
    },
    toSignInOutList() {
      this.$router.push(`/${this.appId}/student/signInOut/list`)
    },
    initData() {
      console.log('initData')
    }
  }
}
</script>
<style lang="less" scoped>
.clazz-container {
  margin-bottom: 1.4rem;
}
.van-tree-select {
  height: 91vh !important;
  .van-sidebar {
    flex: 0.75;
  }
}
</style>
